let app = {};
import { $doc } from "../utils/globals";
import scrollLock from "../utils/scroll-lock";
app.forms = {
	init: function () {
		let self = this;

		if (typeof Marionette !== "undefined") {
			let formSubmitController = Marionette.Object.extend({
				initialize: function () {
					this.listenTo(
						Backbone.Radio.channel("forms"),
						"submit:response",
						this.actionSubmit
					);
				},
				actionSubmit: function (response) {
					let formID = response.data.form_id;
					let $formWrap = $( "#nf-form-" + formID + "-cont" );
					setTimeout( function() {
						if ( !$( $formWrap ).find( ".nf-fail" ).length ) {
							let resultMsg = $formWrap.find( ".nf-response-msg" ).html();
							$( ".popup-success-message p" ).html( resultMsg );
							$( ".popup" ).removeClass( "is-active" );
							scrollLock.enable();
							$( ".popup-success" ).addClass( "is-active" );
						}
					}, 1000 );

					setTimeout(function () {
						self.ninjaFormsInit( "#nf-form-" + formID + "-cont" );
					}, 300);
				},
			});
			new formSubmitController();
		}
	},
	ninjaFormsInit: function ( formElementID ) {
		let self = this;
	
		let $form = $( formElementID );
	
		$form.on("focus", "input:not([type=button]):not([type=radio]):not([type=checkbox]), textarea, select", function () {
			let $this = $(this),
				$fieldWrap = $this.closest(".field-wrap"),
				$labelWrap = $fieldWrap.find(".nf-field-label");
			$labelWrap.addClass("active focused");
		});
	
		$form.on("blur", "input:not([type=button]):not([type=radio]):not([type=checkbox]), textarea, select", function () {
			let $this = $(this),
				$fieldWrap = $this.closest(".field-wrap"),
				$labelWrap = $fieldWrap.find(".nf-field-label");
			if ($this.val()) {
				$labelWrap.removeClass("focused");
			} else {
				$labelWrap.removeClass("focused active");
			}
		});
	
		self.ninjaFormsInitSelects( formElementID );
	},	
	ninjaFormsInitSelects: function ( formID ) {
		let $form = $(formID),
		$selects = $form.find( "select" );

		$selects.each( function ( i, item ) {
			let $select = $(item),
				$placeholder = $select.find( "option:first-child" );

			$placeholder.attr( "value", "" ).text( "" );
		});
	},
};

window.addEventListener( 'DOMContentLoaded', ( event ) => {
	app.forms.init();
});

$( document ).on( "nfFormReady", function ( e, ninjaform ) {
	let formElementID = ninjaform.el;
	app.forms.ninjaFormsInit( formElementID );

	var $applicationTypeField = $( ".application-type-field" ),
		formInitFields = function() {
			setTimeout( function() {
				$( document ).trigger( "application-form-init", [ ninjaform.$el ] );
			} );
		};

	formInitFields();

	$ ( document ).on( "click", ".nf-previous, .nf-next, .nf-breadcrumb", function( event ) {
		event.preventDefault();
		formInitFields();
	} );
} );