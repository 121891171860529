import { $doc } from "../utils/globals";
import scrollLock from "../utils/scroll-lock";
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

$doc.on( "click", ".js-popup-request", function( e ) {
	e.preventDefault();

	if ( $( ".popup.is-active" ).length === 0 ) {
		scrollLock.enable();
	}

	$( ".popup.popup--form" ).addClass( "is-active" );
} );

$doc.on( "click", ".js-popup-portfolio", function( e ) {
	e.preventDefault();

	if ( $( ".popup.is-active" ).length === 0 ) {
		scrollLock.enable();
	}
	$( ".popup.popup--portfolio" ).addClass( "is-active" );
} );

$doc.on(
	"click",
	".popup-not-video .popup__close",
	function( e ) {
		e.preventDefault();
	}
);

$doc.on( "click", function( e ) {
	if ( $( ".popup-not-video.is-active" ).length > 0) {
		if ( $( ".job-form" ).length && $(".popup-success").hasClass( "is-active" ) ) {
			location.reload();
		}

		else if ( !$( e.target ).closest( ".popup__inner-content" ).length ) {
			$( ".popup" ).removeClass( "is-active" );

			if ( $( ".popup.is-active" ).length === 0 ) {
				scrollLock.disable();
				$( "body" ).removeClass( "popup-active" );
			}
		}
	}
} );

$doc.on( "keydown", function ( e ) {
	if ( e.key === "Escape" ) {
		if ( $( ".job-form" ).length && $(".popup-success").hasClass( "is-active" ) ) {
			e.preventDefault();
			location.reload();
		} else {
			e.preventDefault();
			$( ".popup-not-video.is-active" ).removeClass( "is-active" );
			scrollLock.disable();
			$( "body" ).removeClass( "popup-active" );
		}
	}
} );

$doc.on( "click", ".hamburger", function() {
	$( "body" ).hasClass( "is-nav-active" )
		? scrollLock.enable()
		: scrollLock.disable();
} );

$doc.on(
	"click",
	".header__menu--bg",
	function() {
		scrollLock.disable();

		$( "body" ).removeClass( "is-nav-active" );

		$( ".header" ).removeClass( "menu-open" );
	}
);

$( window ).on( "resize", function() {
	var win = $( this );
	if ( win.width() > 1199 ) {
		if ( $( ".header" ).hasClass( "menu-open" ) ) {
			$( "header" ).removeClass( "menu-open" );
			$( "body" ).removeClass( "is-nav-active" );
			scrollLock.disable();
		}
	}
} );

let projectThumb;
let projectMainPhoto;

const initPopupSlider = async function() {
	const { Swiper } = await import( "swiper" );
	await import( "swiper/css/bundle" );
	const { Navigation, Thumbs, Controller } = await import( "swiper/modules" );

	const $sliderPopup = $( ".popup__project--slider" );
	const $sliderPopupThumbs = $( ".popup__project--thumbs--slider" );
	
	if ( $sliderPopupThumbs.length ) {
		projectThumb = new Swiper( $sliderPopupThumbs[0], {
			modules: [Navigation, Thumbs, Controller],
			spaceBetween: 4,
			slidesPerView: 2,
			navigation: {
				prevEl: ".popup__project--thumbs--prev",
				nextEl: ".popup__project--thumbs--next",
			},
			breakpoints: {
				1024: {slidesPerView: 4},
				600: {slidesPerView: 3},
			},
		} );

		projectThumb.init();
	}

	if ( $sliderPopup.length ) {
		projectMainPhoto = new Swiper( $sliderPopup[0], {
			modules: [Navigation, Thumbs, Controller],
			slidesPerView: 1,
			effect: "fade",
			allowTouchMove: false,
			thumbs: {
				swiper: projectThumb,
			},
		} );
		projectMainPhoto.init();
	}

	if ( projectMainPhoto && projectThumb ) {
		projectMainPhoto.controller.control = projectThumb;
	}
};
 
themeUtils.loadBlock( initPopupSlider, "projects", ".projects" );

$doc.on( "click", ".js-popup-projects", function( e ) {
	e.preventDefault();

	let index = $( this ).data( "index" );

	$.ajax( {
		type: "POST",
		url: hm_global.ajax_url,
		data: {
			action: "projects_popup",
			index: index,
		},
		beforeSend: function () {
			$( "body" ).addClass( "is-ajax-loading" );
		},
		success: function( response ) {
			if ( response.success ) {
				$( "body" ).removeClass( "is-ajax-loading" );
				scrollLock.enable();
				let modalContent = response.data.html;
				$( ".popup.popup--projects" ).addClass( "is-active" );
				$( ".js-projects-content" ).html( modalContent );

				initPopupSlider( $( ".js-projects-content" ) );
			}
		},
		error: function() {
			$( "body" ).removeClass( "is-ajax-loading" );
		},
	} );
} ) ;
